import { BottomSheet, CbhIcon, Divider } from "@clipboard-health/ui-components";
import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  addDays,
  addHours,
  differenceInDays,
  endOfDay,
  format,
  isAfter,
  isBefore,
  max,
  min,
  startOfDay,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useState } from "react";

import { type InterviewAvailability } from "../api/useGetInterviewAvailability";
import { InterviewDateSelector } from "./InterviewDateSelector";
import { InterviewTimeSelector } from "./InterviewTimeSelector";
import { PLACEMENT_ACTION_TYPE, type PlacementActionType } from "./PlacementPrimaryAction";

// Helper function to calculate availability date range
function getAvailabilityDateRange(interviewAvailability: InterviewAvailability, now: Date) {
  if (interviewAvailability.length === 0) {
    return { minDate: now, maxDate: addDays(now, 1) };
  }

  const allDates = interviewAvailability.map((slot) => slot.start);
  const earliestDate = min(allDates);
  const latestDate = max(allDates);

  // Ensure we never go earlier than now
  const minDate = isBefore(earliestDate, now) ? now : earliestDate;

  return { minDate, maxDate: latestDate };
}

interface PlacementDetailBookInterviewDialogProps {
  isProspectWorkplace: boolean;
  placementActionType: PlacementActionType;
  modalState: UseModalState;
  interviewAvailability: InterviewAvailability;
  onBookInterview: (time: { start: Date; end: Date }) => void;
}

export function PlacementDetailBookInterviewDialog(props: PlacementDetailBookInterviewDialogProps) {
  const {
    isProspectWorkplace,
    placementActionType,
    modalState,
    interviewAvailability,
    onBookInterview,
  } = props;
  const worker = useDefinedWorker();

  // Build a dictionary: "YYYY-MM-DD" => [{ start: Date, end: Date }, ...]
  const availabilityMap = interviewAvailability.reduce<
    Record<string, Array<{ start: Date; end: Date }>>
  >((accumulator, slot) => {
    const startDate = format(slot.start, "yyyy-MM-dd");
    accumulator[startDate] ||= [];

    accumulator[startDate].push({
      start: slot.start,
      end: slot.end,
    });
    return accumulator;
  }, {});

  const now = utcToZonedTime(new Date(), worker.tmz);
  // Minimum of 2 hours in the future
  const minimumBookingTime = addHours(now, 2);

  // Calculate the earliest and latest available dates from the interview availability
  const availabilityDateRange = getAvailabilityDateRange(interviewAvailability, now);

  // Calculate the total date range in days
  const totalAvailableDays =
    differenceInDays(availabilityDateRange.maxDate, availabilityDateRange.minDate) + 1;
  /**
   * Given a date, return all valid time slots (by start time).
   * Each slot is returned as a Date of the "start" field.
   */
  const generateTimeSlots = (date: Date) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const dailySlots = availabilityMap[dateKey] || [];
    // Filter out slots that start before the minimum booking time
    return dailySlots.filter((slot) => isAfter(slot.start, minimumBookingTime));
  };

  /**
   * Check if a given date has at least one valid time slot.
   */
  const hasValidTimeSlots = (date: Date) => {
    const slots = generateTimeSlots(date);
    return slots.length > 0;
  };

  /**
   * Returns available dates (within `daysToCheck` days from `startDate`)
   * that have at least one valid slot. No longer limiting to only 2 dates.
   */
  const getAvailableDates = (startDate: Date, daysToCheck: number) => {
    const dates: Date[] = [];
    let currentDate = startDate;
    // Look up to daysToCheck days ahead, but don't go beyond the max availability date
    for (let index = 0; index <= daysToCheck; index += 1) {
      if (isAfter(currentDate, availabilityDateRange.maxDate)) {
        break;
      }

      if (hasValidTimeSlots(currentDate)) {
        dates.push(currentDate);
      }

      currentDate = addDays(currentDate, 1);
    }

    return dates;
  };

  // Initialize which days are available in the near future
  const initialDates = getAvailableDates(availabilityDateRange.minDate, totalAvailableDays);

  // Our currently selected date and time
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(initialDates[0] || undefined);
  const [selectedTime, setSelectedTime] = useState<{ start: Date; end: Date } | undefined>(
    undefined
  );

  // Generate the list of time slots for the selected date
  const timeSlots = selectedDate ? generateTimeSlots(selectedDate) : [];

  /**
   * Handle when a user clicks on a day in the calendar UI.
   */
  const handleDateChange = (date: Date) => {
    if (hasValidTimeSlots(date)) {
      setSelectedDate(date);
    } else {
      // Look for available dates starting from the selected date
      const nextDates = getAvailableDates(date, totalAvailableDays);
      setSelectedDate(nextDates[0] || undefined);
    }
  };

  /**
   * Controls whether a day in the calendar is clickable or not.
   */
  const shouldDisableDate = (date: Date) => {
    // Past the current day?
    if (isBefore(date, startOfDay(now))) {
      return true;
    }

    // Past the maximum available day?
    if (isAfter(date, endOfDay(availabilityDateRange.maxDate))) {
      return true;
    }

    // If it has no valid time slots, disable it
    return !hasValidTimeSlots(date);
  };

  const availableDays = getAvailableDates(availabilityDateRange.minDate, totalAvailableDays);
  const isInterviewSlotAvailable = interviewAvailability.length > 0;

  const scheduleLabel = isProspectWorkplace
    ? "Schedule yourself as a walk-in"
    : "Schedule an in-person interview";
  const rescheduleLabel = isProspectWorkplace ? "Reschedule walk-in" : "Reschedule interview";

  const buttonText =
    placementActionType === PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW ? scheduleLabel : rescheduleLabel;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {isInterviewSlotAvailable && (
            <>
              <Text variant="body2" align="center">
                By continuing, you agree that we are authorized to contact you for the interview
                attendance confirmation via email, sms or a phone call.
              </Text>
              <LoadingButton
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                isLoading={false}
                disabled={!selectedDate || !selectedTime}
                onClick={async () => {
                  if (!selectedTime) {
                    return;
                  }

                  onBookInterview(selectedTime);
                }}
              >
                {buttonText}
              </LoadingButton>
            </>
          )}
        </DialogFooter>
      }
    >
      <Stack spacing={6} sx={{ px: 6, py: 8 }}>
        {isInterviewSlotAvailable ? (
          <>
            <Text variant="h4" align="left">
              Choose your {isProspectWorkplace ? "walk-in" : "interview"} time
            </Text>
            <InterviewDateSelector
              weekDays={availableDays}
              selectedDate={selectedDate}
              shouldDisableDate={shouldDisableDate}
              onDateChange={handleDateChange}
            />
            <Divider />

            <InterviewTimeSelector
              timeSlots={timeSlots}
              selectedTime={selectedTime}
              onTimeSelect={setSelectedTime}
            />
          </>
        ) : (
          <Stack
            spacing={4}
            sx={{ p: 2, height: "300px" }}
            alignItems="center"
            justifyContent="center"
          >
            <CbhIcon type="info" color={(theme) => theme.palette.warning.main} size="large" />
            <Text semibold variant="h5" align="center">
              No {isProspectWorkplace ? "walk-in" : "interview"} slots are available at this time.
              Please check back later.
            </Text>
          </Stack>
        )}
      </Stack>
    </BottomSheet>
  );
}
