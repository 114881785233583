import { CbhIcon } from "@clipboard-health/ui-components";
// TODO: Use our own Input
// eslint-disable-next-line no-restricted-imports
import { TextField } from "@mui/material";
import { hideKeyboardOnPressEnter } from "@src/appV2/lib/utils/capacitor";

interface SearchFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export function SearchField(props: SearchFieldProps) {
  const { value, onChange } = props;

  return (
    <TextField
      fullWidth
      aria-label="Search by facility name"
      variant="outlined"
      size="small"
      type="search"
      InputProps={{
        inputMode: "search",
        startAdornment: <CbhIcon type="search" size="small" />,
        size: "small",
        sx: {
          borderRadius: 8,
        },
        endAdornment: value ? (
          <CbhIcon
            type="close"
            size="medium"
            onClick={() => {
              onChange("");
            }}
          />
        ) : undefined,
      }}
      placeholder="&nbsp;Search by facility name"
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      onKeyPress={hideKeyboardOnPressEnter}
    />
  );
}
