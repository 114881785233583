import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

interface PlacementInterviewMessageProps {
  isProspectWorkplace: boolean;
}

export function PlacementInterviewMessage(props: PlacementInterviewMessageProps) {
  const { isProspectWorkplace } = props;

  return (
    <Stack direction="column" spacing={2}>
      <Text
        variant="subtitle1"
        sx={{ fontWeight: 500, color: (theme) => theme.palette.intent?.success.text }}
      >
        Same-day {isProspectWorkplace ? "" : "interview and"} offer
      </Text>
      <Text variant="body2" sx={{ color: (theme) => theme.palette.intent?.success.text }}>
        Schedule your {isProspectWorkplace ? "walk-in" : "interview"} time and receive an offer by
        the end of the day.
      </Text>
    </Stack>
  );
}
