import { LoadingButton, Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { Button } from "../../components/Button";

export const PLACEMENT_ACTION_TYPE = {
  CREATE_PROFILE: "CREATE_PROFILE",
  BOOK_INTERVIEW: "BOOK_INTERVIEW",
  RESCHEDULE_INTERVIEW: "RESCHEDULE_INTERVIEW",
  APPLY: "APPLY",
  UPLOAD_LICENSE: "UPLOAD_LICENSE",
} as const;

export type PlacementActionType =
  (typeof PLACEMENT_ACTION_TYPE)[keyof typeof PLACEMENT_ACTION_TYPE];

export interface PrimaryActionProps {
  type: PlacementActionType;
  label: string;
  warning?: string;
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
}

interface PlacementPrimaryActionProps {
  isProspectWorkplace: boolean;
  primaryActionProps: PrimaryActionProps;
}

export function PlacementPrimaryAction(props: PlacementPrimaryActionProps) {
  const { isProspectWorkplace, primaryActionProps } = props;

  switch (primaryActionProps.type) {
    case PLACEMENT_ACTION_TYPE.CREATE_PROFILE: {
      return (
        <>
          <Button fullWidth variant="contained" size="large" onClick={primaryActionProps.onClick}>
            {primaryActionProps.label}
          </Button>
          <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
            You must create a profile before you can apply for this job.
          </Text>
        </>
      );
    }

    case PLACEMENT_ACTION_TYPE.UPLOAD_LICENSE: {
      return (
        <>
          {primaryActionProps.warning && (
            <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {primaryActionProps.warning}
            </Text>
          )}
          <Button fullWidth variant="contained" size="large" onClick={primaryActionProps.onClick}>
            {primaryActionProps.label}
          </Button>
        </>
      );
    }

    case PLACEMENT_ACTION_TYPE.BOOK_INTERVIEW: {
      return (
        <Stack spacing={5}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </Button>
          <Stack spacing={1}>
            <Text variant="body1" textAlign="center">
              You&apos;ll receive $20 for travel expenses once
            </Text>
            <Text variant="body1" textAlign="center">
              your {isProspectWorkplace ? "walk-in" : "interview"} is completed
            </Text>
          </Stack>
        </Stack>
      );
    }

    case PLACEMENT_ACTION_TYPE.APPLY: {
      return (
        <>
          {primaryActionProps.warning && (
            <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
              {primaryActionProps.warning}
            </Text>
          )}
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            isLoading={primaryActionProps.isLoading}
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </LoadingButton>
        </>
      );
    }

    case PLACEMENT_ACTION_TYPE.RESCHEDULE_INTERVIEW: {
      return (
        <Stack spacing={5}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            disabled={primaryActionProps.disabled}
            onClick={primaryActionProps.onClick}
          >
            {primaryActionProps.label}
          </Button>
        </Stack>
      );
    }

    default: {
      return null;
    }
  }
}
